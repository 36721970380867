import {Injectable} from '@angular/core';
import {BehaviorSubject, interval} from "rxjs";
import { Time } from "@models/time.model";
import {FsService} from "./fs.service";
import {Shop} from "@models/shop.model";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public availableDates: number[] = [];
  private openingTime: Date = new Date();
  private closingTime: Date = new Date();
  public time$: BehaviorSubject<Time> = new BehaviorSubject<Time>(new Time(
    new Date(),
    new Date(this.roundFiveMinutesUp(new Date())),
    this.getMoment(0,0),
    this.getMoment(0,0)
  ));
  public timeOptions: string[] = [];

  constructor(private fs: FsService) {

    this.fs.shop$.subscribe((shop: Shop | null): void => {
      if(shop){
        this.openingTime = shop.getOpeningTime()
        this.closingTime = shop.getClosingTime()

        if(this.openingTime != this.time$.value.openingTime || this.closingTime != this.time$.value.closingTime){
          let availableDate = new Date(this.openingTime.getTime());
          this.availableDates = [];
          while(availableDate <= this.closingTime){
            this.availableDates.push(availableDate.getTime());
            availableDate = new Date(availableDate.getTime() + 5*60*1000);
          }
        }

        this.time$.next(new Time(
          new Date(),
          new Date(this.roundFiveMinutesUp(new Date())),
          this.openingTime,
          this.closingTime
        ));
      }
    });

    this.startTimer();
    this.timeOptions = this.setOption();
  }

  private setOption(): string[]{
    let options: string[] = [];
    for(let h: number = 0; h < 24; h++){
      for(let m: number = 0; m < 60; m = m + 5){
        options.push(('0' + h).slice(-2)+':'+('0' + m).slice(-2));
      }
    }
    return options
  }

  public async startTimer(): Promise<void> {
    /* synchronisation des montres */
    let secondeBeforeNextMinute: number = 60 - new Date().getSeconds();
    await new Promise(resolve => setTimeout(resolve, 1000 * secondeBeforeNextMinute));
    this.time$.next(new Time(
      new Date(),
      this.roundFiveMinutesUp(new Date()),
      this.openingTime,
      this.closingTime
    ));

    /* interval emitter */
    interval(60 * 1000).subscribe(() => {
      this.time$.next(new Time(
        new Date(),
        this.roundFiveMinutesUp(new Date()),
        this.openingTime,
        this.closingTime
      ));
    });
  }

  public getMoment(h: number, m: number): Date {
    const now: Date = new Date();
    return new Date(now.setHours(h, m, 0, 0));
  }

  public roundNearestFiveMinutes(date: Date): Date {
    /* rounded date to the NEAREST 5 minutes */
    let coeff = 1000 * 60 * 5; // 5 minutes en milliseconde
    return new Date(Math.round(date.getTime() / coeff) * coeff)
  };

  public roundFiveMinutesUp(date: Date): Date {
    /* rounded date to the next 5 minutes */
    const coeff: number = 1000 * 60 * 5;
    return new Date(Math.ceil(date.getTime() / coeff) * coeff)
  }

  public addFiveMinutes(date: Date): Date {
    /* increase date by 5 minutes */
    let coeff: number = 1000 * 60 * 5;
    return new Date(date.getTime() + coeff);
  }

  public formatDate(date: Date): string {
    /* return month in French */
    return date.toLocaleDateString('fr-FR', {month: '2-digit'});
  }
}

