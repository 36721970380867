import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Language {
  name: string;
  flag: string;
  greetingTitle: string;
  greetingSubTitle: string;
  locale?: string;
}

export interface Languages {
  [locale: string]: Language;
}

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private languages: Languages = {
    'fr-FR': {
      name: "FRANCAIS",
      flag: "https://flagcdn.com/fr.svg",
      greetingTitle: "Bienvenue",
      greetingSubTitle: "Veuillez choisir votre langue",
    },
    'en-GB': {
      name: "ENGLISH",
      flag: "https://flagcdn.com/gb.svg",
      greetingTitle: "Welcome",
      greetingSubTitle: "Please choose your language",
    },
    'de-DE': {
      name: "DEUTSCH",
      flag: "https://flagcdn.com/de.svg",
      greetingTitle: "Willkommen",
      greetingSubTitle: "Bitte wählen Sie Ihre Sprache",
    },
    'it-IT': {
      name: "ITALIANO",
      flag: "https://flagcdn.com/it.svg",
      greetingTitle: "Benvenuto",
      greetingSubTitle: "Si prega di scegliere la propria lingua",
    },
    'es-ES': {
      name: "ESPAÑOL",
      flag: "https://flagcdn.com/es.svg",
      greetingTitle: "Bienvenido",
      greetingSubTitle: "Por favor elige tu idioma",
    },
    'nl-NL': {
      name: "NEDERLANDS",
      flag: "https://flagcdn.com/nl.svg",
      greetingTitle: "Welkom",
      greetingSubTitle: "Kies uw taal",
    }
  };

  private selected$: BehaviorSubject<Language | null> = new BehaviorSubject<Language | null>(null);

  constructor() { }

  public getLanguages(): Languages {
    return this.languages;
  }

  public getLocaleData(locale: string): Language | null {
    /** get data from languages using locale param **/
    return this.languages[locale] ? this.languages[locale] : null;
  }

  public getLanguagesAsArray(): Language[] {
    const languagesAsArray: Language[] = [];
    for (const locale in this.languages) {
      let item: Language = this.languages[locale];
      Object.assign(item, { locale: locale });
      languagesAsArray.push(item);
    }
    return languagesAsArray;
  }

  public setSelected(locale: string): void {
    /** set selected languages using a locale, like 'fr-FR', 'en-GB', 'es-ES', 'it-IT', 'nl-NL' etc... **/
    this.selected$.next(this.languages[locale] ? { ...this.languages[locale], locale } : null);
  }

  public dismountSelected(): void {
    /** dismount selected language */
    this.selected$.next(null);
  }

  public get selectedFlag$(): Observable<string | null> {
    /** return url of the selected language flag **/
    return this.selected$.asObservable().pipe(
      map((selected: Language | null) => selected ? selected.flag : null)
    );
  }

  public get selectedLocal$(): Observable<Language | null>{
    return  this.selected$.asObservable();
  }
}
